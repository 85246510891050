import React, { MouseEventHandler, useMemo } from 'react';
import { useRouter } from 'next/router';

import { ClientApiService } from 'services/ClientApiService';

import useUser from './useUser';

export type AuthContextType = {
  isLoggedIn: boolean;
  handleLogout: MouseEventHandler<HTMLButtonElement>;
};

export const isLoggedIn = (user: { isLoggedIn: boolean } | undefined): boolean =>
  user?.isLoggedIn ?? false;

export const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const router = useRouter();
  const { user, mutateUser } = useUser();

  const value = useMemo(
    () => ({
      isLoggedIn: isLoggedIn(user),
      handleLogout: async () => {
        await ClientApiService.logout();

        await mutateUser();
        await router.push('/');
      },
    }),
    [user, mutateUser, router]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
