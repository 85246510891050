/**
 * Service used in client to make requests to the API
 */
import { GetAuthStatusInput, StartBankIdInput } from 'lib/auth/types';
import { OrderRole, OrdersPayload } from 'types/apiService/types';

export class ClientApiService {
  static async initializeAuth(params: StartBankIdInput) {
    // TODO: Add return type
    return this.request('/initialize-auth', params);
  }

  static async checkAuth(params: GetAuthStatusInput) {
    return this.request('/check-auth', params);
  }

  static async cancelBankId(orderRef: string) {
    return this.request('/cancel-bankid', { orderRef });
  }

  static async logout() {
    return this.request('/logout');
  }

  static async deleteRedirect() {
    return this.request('/delete-redirect');
  }

  static async postRedirect(redirectTo: string) {
    return this.request('/post-redirect', {
      redirectTo,
    });
  }

  // TODO: Add return type
  static async putUser({ email, phone }: { email: string; phone: string }) {
    const payload = {
      email,
      phone,
      notification_via_email: true,
      notification_via_sms: true,
    };

    return this.request('/put-users', payload);
  }

  // TODO: Add return type
  static async patchVehicle(
    licensePlate: string,
    price: number,
    sellOption: 'inventory' | 'dealership'
  ) {
    return ClientApiService.request(`/patch-vehicle`, {
      licensePlate,
      price,
      sellOption,
    });
  }

  // TODO: Add return type
  static async login(username: string) {
    return ClientApiService.request('/login', {
      username,
    });
  }

  // TODO: Add return type
  static async postOrders(isInventory: boolean, payload: OrdersPayload) {
    return ClientApiService.request('/post-orders', {
      isInventory,
      payload,
    });
  }

  // TODO: Add return type
  static async patchOrders(orderId: string, orderType: OrderRole) {
    return ClientApiService.request('/patch-orders', {
      orderId,
      orderType,
    });
  }

  // TODO: Add return type
  static async putOrders(
    orderId: string,
    orderType: OrderRole,
    payload: { sellerPrice?: number; buyerPrice?: number }
  ) {
    return ClientApiService.request('/put-orders', {
      orderId,
      orderType,
      ...payload,
    });
  }

  // TODO: Add return type
  private static async request(endpoint: string, body = {}) {
    return fetch(`/api${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  }

  static swrFetcher(endpoint: string, payload?: {} | null) {
    return fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        const clonedRes = res.clone();
        clonedRes.json().then((data) => {
          if (data.body?.status_code === 401 && data.body?.not_authenticated) {
            fetch('/api/logout', { method: 'POST' }).then(() => {
              // Check if we are within app but not already on the login page
              if (
                window.location.pathname.includes('app') &&
                !window.location.pathname.includes('login')
              ) {
                window.location.href = '/app/login';
              }
            });
          }
        });
        return res;
      })
      .then((res) => res.json());
  }

  static proxyRequest(payload: {}) {
    return fetch(`/api/proxy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  static async checkCar(licensePlate: string, mileage: string, type: string) {
    return ClientApiService.request('/check-car', {
      licensePlate,
      mileage,
      type,
    });
  }
}
