import React, { useMemo, useState } from 'react';

// type ErrorType = string[] | { message: string }[];

export interface IStatus {
  title: string;
  type?: 'error';
  errors?: string[];
}

export type StatusContextType = {
  status?: IStatus;
  setErrors: (errors: any) => void;
  clearStatus: () => void;
};

export const StatusContext = React.createContext<StatusContextType | null>(null);

export const StatusProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [status, setStatus] = useState<IStatus>();

  const value = useMemo(
    () => ({
      status,
      setErrors: (errors: any) => {
        let errorArray = errors;

        if (typeof errors[0] === 'object') {
          errorArray = errors.map((error: { message: string }) => error.message);
        }
        setStatus({ title: 'Något gick fel', type: 'error', errors: errorArray });
      },
      clearStatus: () => setStatus(undefined),
    }),

    [status, setStatus]
  );

  return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>;
};
