import React, { useMemo, useState } from 'react';

export interface IMeetupAddress {
  address: string;
  coordinates: string;
  country_code: string;
}

export interface IMeetupTimeslot {
  date: string;
  hour: string;
}

export type MeetupContextType = {
  timeslots?: IMeetupTimeslot[];
  address?: IMeetupAddress;
  setTimeslots: (meetups: IMeetupTimeslot[]) => void;
  setAddress: (address: IMeetupAddress) => void;
};

export const MeetupContext = React.createContext<MeetupContextType | null>(null);

export const MeetupProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [timeslots, setTimeslots] = useState<IMeetupTimeslot[]>();
  const [address, setAddress] = useState<IMeetupAddress>();

  const value = useMemo(
    () => ({ timeslots, setTimeslots, address, setAddress }),
    [timeslots, address]
  );

  return <MeetupContext.Provider value={value}>{children}</MeetupContext.Provider>;
};
