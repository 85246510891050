// TODO: Needs rewrite
import useSWR from 'swr';

import { ClientApiService } from 'services/ClientApiService';

import type { User } from './types';

const useUser = () => {
  const { data: user, mutate: mutateUser } = useSWR<User>('/api/user', ClientApiService.swrFetcher);

  return { user, mutateUser };
};

export default useUser;
